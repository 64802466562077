body {
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  background-image: url("../img/background.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-color: black;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.top {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.head {
  font-size: 0.8em !important;
  margin-top: 1em;
}

.bottom {
  font-size: 0.8em !important;
}

#logo {
  height: 80px;
  width: 255px;
  background-image: url("../img/logo.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.contact {
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5em;
}
